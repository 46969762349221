import classNames from "classnames"
import Block from "@/components/wrappers/block"
import styles from "./list.module.css"

function List({ block, settings }) {
  const { classList, layout } = block.attrs
  const classes = [styles.coreList, classList, block.attrs?.theme ?? ""]

  let width = settings?.width ?? "column"
  width = layout && layout === "2-col" ? "full" : width

  const Element = ({ block }) => {
    return (
      <div
        className={classNames(classes)}
        dangerouslySetInnerHTML={{ __html: block }}
      />
    )
  }

  return (
    <Block
      width={width}
      gap={settings?.blockGap ?? "content"}
      block={block}
      className={classNames(styles.wrapper, settings?.innerAlign, {
        "layout-2-col": layout === "2-col"
      })}>
      <Element block={block.html} />
    </Block>
  )
}

export default List
